import React, {useState,} from 'react'
import { Switch, Route, useLocation} from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import HomeFr from '../french/home/Home'
import PlumbingFr from '../french/plumbing/Plumbing'
import HeatingFr from '../french/heating/Heating'
import UncloggingFr from '../french/unclogging/Unclogging'
import FooterFr from '../french/footer/Footer'
import Contact from '../french/footer/Contact'

import AOS from 'aos';

AOS.init();
const Router = () => {
    let location = useLocation()
    return(
            <AnimatePresence exitBeforeEnter initial={false}>
                <Switch location={location} key={location.pathname}>
                    <Route exact path="/fr/acceuil" component={() => <HomeFr/>}/>
                    <Route exact path="/fr/plomberie" component={() => <PlumbingFr/>}/>
                    <Route exact path="/fr/debouchage" component={() => <UncloggingFr/>}/>
                    <Route exact path="/fr/chauffage" component={() => <HeatingFr/>}/>
                    <Route path="/" component={() => <HomeFr/>}/>
                </Switch>
                <Contact/>
                <FooterFr/>
            </AnimatePresence>

    )
}

export default Router