import React from 'react' 
import LogoImage from '../../../images/newLogo2.jpg' 

const Logo = () => {
    return(
        <React.Fragment>
            <div className="bg-white text-black h-44 text-6xl flex justify-center items-center xs:hidden flex flex-col">
                <img src={LogoImage} className='h-36 block'/>
                <div className='comic text-2xl'>
                {/* <span className='text-black'>Chauff</span><span className='text-yellow-400'>agiste</span> <span className='text-red-700'>Belgium</span> */}
                </div>
                <span href="tel:0032470178102" className="comic  block xs:hidden text-center  text-blue-500 text-sm lg:text-3xl" data-aos="fade-right" data-aos-delay="1000" data-aos-duration="2000">Un service de qualité, en toute rapidité ! </span>
            </div>
            
        </React.Fragment>
    )
}

export default Logo