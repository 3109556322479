const stats = [
    { label: 'Founded', value: '2021' },
    { label: 'Employees', value: '5' },
    { label: 'Beta Users', value: '521' },
    { label: 'Raised', value: '$25M' },
  ]
  import Heather from '../../../images/heater2.jpeg'
  
  export default function Example() {
    return (
      <div className="relative bg-white pb-16 sm:pb-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
          <div className='order-last self-start'>
            <div className="relative sm:py-16 lg:py-0 hidden lg:block">
            <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
              <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />
              <svg
                className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
              </svg>
            </div>
            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
              <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                <img
                  className="absolute inset-0 h-full w-full object-cover"
                  src={Heather}
                  alt=""
                />
                <div className="relative px-8">
                  <blockquote className="mt-8">
                    <div className="relative text-lg font-medium text-white md:flex-grow">
                    </div>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
          </div>
          
  
          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            <div className="pt-12 sm:pt-16 lg:pt-20">
              
              
            <div className="relative py-12 sm:py-16 lg:py-0 block lg:hidden">
            <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
              <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />

            </div>
            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
              <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                <img
                  className="absolute inset-0 h-full w-full object-cover"
                  src={Heather}
                  alt=""
                />
                <div className="relative px-8">
                  <blockquote className="mt-8">
                    <div className="relative text-lg font-medium text-white md:flex-grow">
                    </div>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
              <h2 data-aos="fade-down" className="text-blue-500 text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                Nous dépannons toutes les marques
              </h2>
                <div className="mt-5 prose prose-blue text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <ul className="list">
                    <li data-aos="fade-right">Dépannage chaudière vaillant</li>
                    <li data-aos="fade-right">Dépannage chaudière bulex</li>
                    <li data-aos="fade-right">Dépannage chaudière junkers</li>
                    <li data-aos="fade-right">Dépannage chaudière elco</li>
                    <li data-aos="fade-right">Dépannage chaudière oertli</li>
                    <li data-aos="fade-right">Dépannage chaudière viessmann</li>
                    <li data-aos="fade-right">Dépannage chaudière buderus</li>
                    <li data-aos="fade-right">Dépannage chaudière riello</li>
                    <li data-aos="fade-right">Dépannage chaudière brotje</li>
                    <li data-aos="fade-right">Dépannage chaudière chappee</li>
                    <li data-aos="fade-right">Dépannage chaudière acv</li>
                    <li data-aos="fade-right">Dépannage chaudière sime</li>
                    <li data-aos="fade-right">Dépannage chaudière ferroli</li>
                    <li data-aos="fade-right">Dépannage chaudière ariston</li>
                    <li data-aos="fade-right">Dépannage chaudière radson</li>
                    <li data-aos="fade-right">Dépannage chaudière chaffoteaux et maury</li>
                    <li data-aos="fade-right">Dépannage chaudière de Dietrich</li>
                    <li data-aos="fade-right">Dépannage chaudière weishaupt</li>
                    <li data-aos="fade-right">Dépannage chaudière wolf</li>
                    <li data-aos="fade-right">Dépannage chaudière remeha</li>
                    <li data-aos="fade-right">Dépannage chaudière ideal standard</li>
                    <li data-aos="fade-right">atlantic - grunfos - wilo - Honeywell</li>
                    </ul>
                </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  