
import Image1 from '../../../images/sponsor/1.jpg'
import Image2 from '../../../images/sponsor/2.png'
import Image3 from '../../../images/sponsor/3.jpg'
import Image4 from '../../../images/sponsor/4.png'
import Image5 from '../../../images/sponsor/5.png'
import Image6 from '../../../images/sponsor/6.png'
import Image7 from '../../../images/sponsor/7.png'
import Image8 from '../../../images/sponsor/8.png'

const sponsors = [Image2, Image3, Image4, Image5, Image6, Image7, Image8]
export default function Example() {
    return (
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <p className="text-center text-3xl text-blue-500 font-bold uppercase text-gray-600 tracking-wider">
            Nos parternaires
          </p>
          <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img
                className="max-h-24"
                src={Image1}
                alt="Workcation"
              />
            </div>
            {sponsors.map((sponsor) => (
                <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                    <img className="max-h-24" src={sponsor} alt="Mirage" />
                </div>
            ))}
            
            
          </div>
        </div>
      </div>
    )
  }
  