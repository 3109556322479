import React from 'react' 

import {PhoneIcon} from '@heroicons/react/outline'

const Phone = () => {
     return(
         <React.Fragment>
            <a href="tel:0032470178102" className='fixed z-50 rounded-full bg-red-700 right-6 top-6  xs:right-20 xs:top-20 h-16 w-16 p-3'>
                <div className='absolute animate-myPing z-48 bg-red-700 w-10 h-10 rounded-full'></div>
                <div className='absolute w-10 h-10 z-49'>
                    <PhoneIcon className='text-blue-500 text-sm'/>
                </div>
            </a>
            
         </React.Fragment>
     )
}

export default Phone