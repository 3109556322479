
import PlumberImage from '../../../images/plumber.jpg'
import PlumbingImage3 from '../../../images/istockphoto-918319088-170667a.jpg'

export default function Example() {
  return (
    <div className="bg-white overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-0 xs:py-16 px-4 sm:px-6 lg:px-8">
        <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <svg
              className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
            </svg>
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                    className="rounded-lg shadow-lg object-cover object-center"
                    src={PlumberImage}
                    alt="Whitney leaning against a railing on a downtown street"
                    width={1184}
                    height={1376}
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
          <h3 data-aos="fade-down" className="text-blue-500 mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Installation plomberie
            </h3>
            <div data-aos="fade-right" className="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              Besoin d’une installation plomberie , nos plombiers peuvent installer toute type d’installation, concernant la plomberie ( installer un WC une baignoire, renouveler une tuyauterie ou quelconque installation de plomberie )<br/><br/>
              Pour tous renseignement n’hésitez pas à nous contacter au <span className='inline-block animate-beating text-blue-500'>0470/17.81.02</span>
            </div>
            <div className='px-6 py-8 block xs:hidden'>
              <img src={PlumbingImage3} className='rounded-lg shadow-lg object-cover object-center'/>
            </div>
            <h3 data-aos="fade-down" className="mt-8 text-blue-500 mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Réparation plomberie
            </h3>
            
            <div data-aos="fade-right" className="mt-5 mb-8 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              Besoin d'une réparation plomberie que sa soit une réparation de siphon ,fuite au niveau d’un raccord ou tuyauterie une réparation de fuite d’eau, ou autre…)<br/><br/>
              N’hésitez pas a nous contactez au <span className='inline-block animate-beating text-blue-500'>0470/17.81.02</span>, nous sommes à votre disposition 24H24 7J7. 
            </div>
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
  )
}
