export default function Example() {
    return (
      <div className="bg-blue-500">
        <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            <span className="block">Un tuyau encombré ?</span>
            <span className="block">Une toilette bouchée ?</span>
          </h2>
          <p className="mt-4 text-lg leading-6 text-blue-200">
            Si vous constatez un écoulement de vos eaux usés (cuisine, wc, salle de bain), il est possible que cela vienne de vos canalisations.<br/><br/> 

            Encombrement des systèmes d'évacuation, dépôt sur les parois des tuyaux, bouchons, ... nous vous recommandons de faire appel à un professionnel pour un diagnostic et une interventions sur votre réseau d'eau. <br/>
            Avez vous, un mauvais écoulement, l'eau stagnante provoque des odeurs désagréables.<br/><br/>

            Nos services de débouchages, s'adressent aux particulier et aux professionnels .<br/>
            Nos professionnels sont la pour identifier rapidement le problème grâce à notre expérience et nos équipements.
          </p>
          <a
            href="#"
            className="animate-bounce mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-blue-50 sm:w-auto"
          >
            Nous contacter
          </a>
        </div>
      </div>
    )
  }
  