import React from 'react'  
import {ClockIcon, GlobeIcon, TruckIcon} from '@heroicons/react/outline'

import BancontactImage from '../../../images/bancontact.png'
const Possibilities = () => {
    return(
        <React.Fragment>
            <div className="flex pt-4 xs:flex hidden">
                <div className="flex-1 flex items-center flex-col">
                    <ClockIcon className="h-8 text-blue-700"/>
                    <p className="text-xxs">En urgence</p>
                    <p className="text-xxs">24h/24 7j/7</p>
                </div>
                <div className="flex-1 flex items-center flex-col">
                    <TruckIcon className="h-8 text-blue-700"/>
                    <p className="text-xxs">Possibilité</p>
                    <p className="text-xxs">d'intervention</p>
                    <p className="text-xxs">en 30 min</p>
                </div>
                <div className="flex-1 flex items-center flex-col">
                    <img src={BancontactImage} className="h-8"/>
                    <p className="text-xxs">Bancontact</p>
                    <p className="text-xxs">Mister Cash</p>
                    <p className="text-xxs">Liquide</p>
                </div>
                <div className="flex-1 flex items-center flex-col">
                    <GlobeIcon className="h-8 text-blue-700"/>
                    <p className="text-xxs">Intervention</p>
                    <p className="text-xxs">dans toute</p>
                    <p className="text-xxs">la Belgique</p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Possibilities