import React from 'react' 
import {PhoneIcon ,MailIcon} from '@heroicons/react/solid'
const Contact = () => {
    return(
        <React.Fragment>
            <div className='py-28 background_hero grid justify-center items-center' id='contact'>
                <div className='p-6 bg-black bg-opacity-75 '>
                    <h3 className='text-white font-bold text-xl text-center xs:text-3xl border-b-4 border-blue-500 pb-2 mb-6'>
                        NOUS CONTACTER
                    </h3>
                    <div className='flex flex-col gap-y-8'>
                        <div className='flex flex-col xs:flex-row gap-x-2.5 items-center'>
                            <MailIcon className='h-10 w-10 text-blue-500 p-2 border border-blue-500 mb-4 xs:mb-0 '/>
                            <div className='grid items-center justify-center'>
                                <a 
                                    href='mailto:info@belgaservice.be' 
                                    className='text-white text-lg underline text-blue-500 hover:no-underline'
                                >
                                    info@belgaservice.be
                                </a>
                            </div>
                        </div>
                        <div className='flex flex-col xs:flex-row gap-x-2.5 items-center'>
                            <PhoneIcon className='h-10 w-10 text-blue-500 p-2 border border-blue-500 mb-4 xs:mb-0 '/>
                            <div className='grid items-center justify-center'>
                                <a 
                                    href='tel:0032470178102' 
                                    className='text-white text-lg underline text-blue-500 hover:no-underline'
                                >
                                    tel: 0470/17.81.02
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Contact