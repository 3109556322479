import React from 'react'

import HeroSmall from './HeroSmall'
import HeroBig from './HeroBig'

const Hero = () => {
    return(
        <React.Fragment>
            <div className='block xs:hidden'>
                <HeroSmall/>
            </div>
            <div className='hidden xs:block'>
                <HeroBig/>
            </div>
        </React.Fragment>
    )
}

export default Hero