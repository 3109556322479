
export default function Example() {
    return (
      <div className="bg-red-700">
        <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            <span className="block">Dépannage chaudière</span>
          </h2>
          <p className="mt-4 text-lg leading-6 text-red-200">
            Belga Service : Plombier, chauffagiste professionnel depuis plus de 10 ans de<br/><br/>

            Services d’installation et d’entretien de chaudières à gaz, atmosphérique et à condensation, dans votre région ,. Des services de plomberie  (réparation de fuites, pose de robinets, pose de radiateurs, installations…) et de ramonage d’insert et de cheminées.
          </p>
          <a
            href="#"
            className="animate-bounce mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-red-600 bg-white hover:bg-red-50 sm:w-auto"
          >
            Nous contacter
          </a>
        </div>
      </div>
    )
  }
  