
import PlumberImage from '../../../images/chauffagiste-bruxelles.jpeg'
import { CameraIcon } from '@heroicons/react/solid'

export default function Example() {
  return (
    <div className="bg-white overflow-hidden pb-20 xs:pb-0">
      <div className="relative max-w-7xl mx-auto py-0 xs:py-16 px-4 sm:px-6 lg:px-8">
        <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
            <h3 data-aos="fade-down" className="text-red-700 mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Installation chaudière
            </h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <svg
              className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
            </svg>
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                    className="rounded-lg shadow-lg object-cover object-center"
                    src={PlumberImage}
                    alt="Whitney leaning against a railing on a downtown street"
                    width={1184}
                    height={1376}
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div data-aos="fade-right" className="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              Belga Service, vous propose ses services pour l'installation ou le 
              remplacement d'une chaudière à gaz par une neuve ou pour changer une chaudière devenue oblselét, 
              ou ancienne nous vous proposons des modèles dernière génération  (selon votre budget) ,plus 
              performantes et plus écologiques
            </div>
            <h3 data-aos="fade-down" className="mt-16 text-red-700 mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Entretien chaudière à gaz ou à mazout
            </h3>
            <div data-aos="fade-right" className="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              Belga Service, vous propose ses service pour l'entretien annuel de votre chaudière au fioul ou au gaz.<br/><br/> 

              Depuis 2009, votre responsabilité est engagée à fournir annuellement une attestation d'entretien délivrée par un professionnel.<br/><br/>  

              Soyez attentif à la qualité de la visite annuelle réalisée sur votre chaudière.<br/> 
              Un entretien réalisé selon les règles de l'art fait par un technicien chauffagiste

              Faites le choix des professionnels de Belga Service . Vous aurez l'assurance d'une révision méticuleuse et poussée pour prévenir tout risque de panne cet hiver. 
              Accordez votre confiance à notre savoir-faire, notre équipe préservera le confort des vôtres à la maison.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
