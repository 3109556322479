import React,{useLayoutEffect} from 'react' 
import { motion } from 'framer-motion'

import Logo from './sections/Logo'
import Heros from './sections/Heros'
import Incentives from './sections/Incentives'
import Services from './sections/Services'
import CallUs from './sections/CallUs'
import Phone from "../../french/components/Phone"
import Sponsor from './sections/Sponsor'



import 'aos/dist/aos.css'

const Home = () => {
    useLayoutEffect(() => {
        console.log("Unmounting component!")
    })
    return(
        <motion.div
            initial={{scale: 0, transformOrigin:"50% 25%"}}
            animate={{scale: 1}}
            exit={{scale: 0}}
            transition={{duration:0.5}}
        >
            <React.Fragment>
                <Phone/>
                <Logo/>
                <Heros/>
                <Services/>
                <Incentives/>
                <CallUs/>
                <Sponsor/>
            </React.Fragment>
        </motion.div>
    )
}

export default Home