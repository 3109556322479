import PlumbingImage from '../../../images/plumbing2.jpg'


export default function Example() {
    return (
      <div className="relative bg-white">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
            <img
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              src={PlumbingImage}
              alt=""
            />
          </div>
        </div>
        <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:col-start-2 lg:pl-8">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-blue-500 sm:text-4xl">
                Ainsi, chaque jour nous intervenons pour :
              </h3>
              <div className="mt-5 prose prose-blue text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <ul className="list">
                    <li data-aos="fade-right">Le débouchage de toilettes et wc</li>
                    <li data-aos="fade-right">le débouchages de canalisations de salle de bain provenant de la douche, lavabos ou autres</li>
                    <li data-aos="fade-right">le débouchages des sorties d'eau de cuisine</li>
                    <li data-aos="fade-right">le débouchage de fosses septiques avec pompage et curage</li>
                    <li data-aos="fade-right">débouchage urgent</li>
                    <li data-aos="fade-right">débouchage évier</li>
                    <li data-aos="fade-right">débouchage baignoire</li>
                    <li data-aos="fade-right">débouchage wc</li>
                    <li data-aos="fade-right">débouchage canalisation</li>
                    <li data-aos="fade-right">débouchage canalisation des eaux usées.</li>
                    <li data-aos="fade-right">dégorgement de canalisations</li>
                    <li data-aos="fade-right">débouchage canalisations bouchées</li>
                    <li data-aos="fade-right">débouchage égouts</li>
                    <li data-aos="fade-right">débouchage égouts bouchés</li>
                    <li data-aos="fade-right">problème de siphon</li>
                    <li data-aos="fade-right">curage</li>
                    <li data-aos="fade-right">curage des canalisations</li>
                    <li data-aos="fade-right">nettoyage des canalisations</li>
                    <li data-aos="fade-right">débouchage tuyau</li>
                    <li data-aos="fade-right">débouchage tuyauterie</li>
                    <li data-aos="fade-right">débouchage à haute pression</li>
                    <li data-aos="fade-right">débouchage au furet</li>
                    <li data-aos="fade-right">débouchage à l'orbisol</li>
                    <li data-aos="fade-right">débouchage par détection de caméra thermique.</li>
                    <li data-aos="fade-right">vidange fosse septique</li>
                    <li data-aos="fade-right">entretien canalisations</li>
                    <li data-aos="fade-right">broyeur pour un wc bouché</li>
                    </ul>
                </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  